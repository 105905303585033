<script>
import def from "./inputText.vue";
export default {
  extends: def,
  methods: {
    checkValue() {
      if (this.value && this.value.length >= 5 && this.validEmail(this.value)) {
        this.check = true;
      } else {
        this.check = false;
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>
