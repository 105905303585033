<template>
  <div class="flix-form-group">
    <label class="flix-label" v-if="label" :for="id"
      >{{ label }} <help class="flix-help" v-if="help">{{ help }}</help></label
    >
    <input
      class="flix-form-control"
      :class="{ 'flix-border-danger': error }"
      v-model="value"
      :id="id"
      name="title"
      type="text"
      spellcheck="false"
      :placeholder="placeholder"
      :style="styling.input"
    />
    <span
      v-if="error"
      class="flix-text-danger flix-html-strong flix-html-small"
      >{{ error }}</span
    >
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: [String, Number],
    label: String,
    callback: Function,
    placeholder: String,
    error: [Boolean, String],
    styling: [Boolean, Object],
    help: String
  },
  data() {
    return {
      check: false,
      id: new Date().getTime() + Math.random(),
      value: this.data
    };
  },
  computed: {},
  mounted() {
    this.checkValue();
  },
  watch: {
    value() {
      this.check = false;
      this.checkValue();
      this.returnValue();
    }
  },
  methods: {
    returnValue() {
      if (typeof this.callback !== "function") {
        return false;
      }

      if (this.check === true) {
        this.callback(this.value, this.value);
      } else {
        this.callback("", this.value);
      }
    },
    checkValue() {
      if (this.value && this.value.length >= 5) {
        this.check = true;
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
